import HttpService, { catchReporter, checkMaintenance, reporter } from "./httpService";
import toast from "react-hot-toast";
import { toPascalCase } from "../utils/tools";

class UserService {
    private _httpService = HttpService('MyTreasure');
    private _httpServiceIsland = HttpService('Island');

    async getAll(searchQuery?: string, filterStatus?: string, filterType?: string, offset?: any, limit?: number) {
        try {
            const response = await this._httpService.get<any>(`/Overview?limit=${limit ? limit : 1}&offset=${offset ? offset : 1}&islandStatus=${filterStatus === 'all' ? '' : filterStatus}`,
                {
                    params: {
                        searchKey: searchQuery
                    }
                }
            );
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getIsland(id: number) {
        try {
            const response = await this._httpService.get<any>(`/Overview/${id}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getIslandDetail(id: number) {
        try {
            const response = await this._httpService.get<any>(`/IslandDetail/${id}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getListOfTransferableIsland() {
        try {
            const response = await this._httpService.get<any>(`/GetListOfTransferableIsland/`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getApproximatlyAvailable(id: number) {
        try {
            const response = await this._httpService.get<any>(`/ApproximatlyAvailable/${id}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async claimAndCompound(formData: any) {
        try {
            const response = await this._httpServiceIsland.post<any>('/claimAndCompound', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async topUp(formData: any) {
        try {
            const response = await this._httpServiceIsland.post<any>('/Topup', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async claim(formData: any) {
        try {
            const response = await this._httpServiceIsland.post<any>('/Caim', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async claim2(formData: any) {
        try {
            const response = await this._httpServiceIsland.post<any>('/WithdrawAll', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async forceWithdraw(formData: any) {
        try {
            const response = await this._httpServiceIsland.post<any>('/Withdraw', formData);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else {
                reporter(response)
                if (typeof response.data.error === 'string') {
                    throw new Error(response?.data?.error?.toString())
                } else if (response.data.errors) {
                    response.data.errors.map((e: any) => toast.error(`${toPascalCase(e.param)}: ${e.msg}`))
                    throw new Error()
                }
            }
        } catch (ex: any) {
            catchReporter(ex)
            throw new Error(ex)
        }
    }

    async getTransactions(id?: number, filterKey?: number, sortBy?: string, offset?: any) {
        try {
            if (filterKey === -1) {
                const response = await this._httpService.get<any>(`/Transaction/${id}?sortBy=created_at&sortType=${sortBy}&limit=${10}&offset=${offset ? offset : 1}`);
                checkMaintenance(response)
                if (response.data.status) return response?.data?.message
                else reporter(response)
            }
            if (filterKey || filterKey === 0) {
                const response = await this._httpService.get<any>(`/Transaction/${id}?sortBy=created_at&sortType=${sortBy}&filterBy=${Number(filterKey) === 0 ? 'funds' : Number(filterKey) === 2 ? 'defunds' : 'type'}&filterKey=${filterKey}&limit=${10}&offset=${offset ? offset : 1}`);
                checkMaintenance(response)
                if (response.data.status) return response?.data?.message
                else reporter(response)
            } else {
                const response = await this._httpService.get<any>(`/Transaction/${id}&limit=${10}&offset=${offset ? offset : 1}`);
                checkMaintenance(response)
                if (response.data.status) return response?.data?.message
                else reporter(response)
            }

        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getTxChart(id: number, type: string) {
        try {
            const response = await this._httpService.get<any>(`/TransactionChart/${id}?timeframe=${type}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getProfitChart(id: number, type: string) {
        try {
            const response = await this._httpService.get<any>(`/ProfitChart?timeframe=${type}&island_id=${id}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

    async getCrew(id: number) {
        try {
            const response = await this._httpService.get<any>(`/Crew/${id}`);
            checkMaintenance(response)
            if (response.data.status) return response?.data?.message
            else reporter(response)
        } catch (ex: any) {
            if (ex.response && ex.response && ex.response.status === 400) {
                catchReporter(ex)
                throw new Error(ex.response.data.error)
            }
        }
    }

}

export default new UserService()