import { useContext, useEffect, useState } from 'react';
import IslandsContext from '../../../context/private/islandsContext';
import PrivateContext from '../../../context/private/privateContext';
import { Flex } from '../../../styles/Styles';
import { numberWithCommas, timeToYYYYMMDD, timeToYYYYMMDDHH } from '../../../utils/tools';
import { UserChartDaily } from '../../UserChartDaily';
import { UserChartMonthly } from '../../UserChartMonthly';
import { TxChartDaily } from '../chart/transactions-daily-chart';
import { TxChartMonthly } from '../chart/transactions-monthly-chart';
import config from '../../../config';
import { IslandsItemContext } from '../Index';
import dictionary from './../../../locals/island.json';
import AccountContext from '../../../context/private/accountContext';

const WFPeriod = ({ type, data }: any) => {
  const formatDate = (date: any) => {
    return new Intl.DateTimeFormat('en-GB', {
      month: 'long',
      day: '2-digit'
    }).format(date * 1000);
  };

  if (type == 0) {
    return (
      <>
        {data?.day_frequency}
        {data?.day_frequency === 1 ? ' Day' : ' Days'}
      </>
    );
  }

  if (type == 1) {
    return (
      <>
        <div style={{ fontSize: '1.6rem' }}>{`${data?.period_frequency_count
          } Time${data?.period_frequency_count == 1 ? '' : 's'} Between`}</div>
        <div style={{ fontSize: '1.5rem' }}>{`${formatDate(
          JSON.parse(data?.period_frequency)[0]
        )}, ${formatDate(JSON.parse(data?.period_frequency)[1])}`}</div>
      </>
    );
  }
  if (type == 2) {
    return (
      <>
        {dictionary['ISLAND-PAGE-OVERVIEW-title18']}
        {/* { `After, ${timestampToLocalDaily((Number(islandParticipateDetail?.end_date)) / 1000)}, ${timestampToLocalHour((Number(islandParticipateDetail?.end_date)) / 1000)}`} */}
      </>
    );
  }
  if (type == 3) {
    return <>{dictionary['ISLAND-PAGE-OVERVIEW-title19']}</>;
  }

  return <></>;
};

const CircleBar = ({ color, percentage }: any) => {
  const [displayPercentage, setDisplayPercentage] = useState(0);
  useEffect(() => {
    const percentageComplete = percentage / 100;
    const maxCapacity = 100;
    const strokeDashOffsetValue = 100 - percentageComplete * maxCapacity;
    setDisplayPercentage(strokeDashOffsetValue);
  }, [percentage]);
  return (
    <div className="statisticItem">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className="progress-bar__background"
        />

        <circle
          cx="16"
          cy="16"
          r="15.9155"
          className="progress-bar__progress 
                 js-progress-bar"
          strokeDashoffset={displayPercentage}
        />
      </svg>
    </div>
  );
};

const Overview = () => {
  const { loadProfitChart, loadTxChart } = useContext(IslandsContext);

  const { islandOverview, islandCrew, currentId } =
    useContext(IslandsItemContext);

  const [userChart, setUserChart] = useState('monthly');
  const [txChart, setTxChart] = useState('yearly');

  const [profitChartDailyData, setProfitChartDailyData] = useState<any>(null);
  const [profitChartMonthlyData, setProfitChartMonthlyData] =
    useState<any>(null);

  const [txChartDailyData, setTxChartDailyData] = useState<any>(null);
  const [txChartMonthlyData, setTxChartMonthlyData] = useState<any>(null);

  const { flowState, setUpdateTimer } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    if (flowState.step === 17 && userChart && currentId !== -1 && userInfo
      && userInfo?.membershipStatus !== 2) {
      userChart === 'yearly' &&
        loadProfitChart(
          currentId,
          'yearly',
          setProfitChartMonthlyData,
          setProfitChartDailyData
        );
      userChart === 'monthly' &&
        loadProfitChart(
          currentId,
          'monthly',
          setProfitChartMonthlyData,
          setProfitChartDailyData
        );
    }
  }, [currentId, flowState, userChart]);

  useEffect(() => {
    if (flowState.step === 17 && txChart && currentId !== -1 && userInfo
      && userInfo?.membershipStatus !== 2) {
      txChart === 'yearly' &&
        loadTxChart(
          currentId,
          'yearly',
          setTxChartMonthlyData,
          setTxChartDailyData
        );
      txChart === 'monthly' &&
        loadTxChart(
          currentId,
          'monthly',
          setTxChartMonthlyData,
          setTxChartDailyData
        );
    }
  }, [currentId, flowState, txChart]);

  useEffect(() => {
    setUpdateTimer(+new Date());
  }, [txChart, userChart, currentId]);


  const calculateApproximatlyTimeTitle = (type: any) => {
    switch (type) {
      case 0:
        // monthly
        return `Monthly Predicted Rate`
      case 1:
        // daily
        return `Daily Predicted Rate`
      case 2:
        // yearly
        return `Yearly Predicted Rate`

      default:
        // default
        return `Interest Rate`
    }
  }

  const calculateApproximatlyTime = (type: any) => {
    switch (type) {
      case 0:
        // monthly
        return `${numberWithCommas(
          Number(islandOverview?.monthley_rate)
        )}`
      case 1:
        // daily
        return `${numberWithCommas(
          Number(
            islandOverview?.monthley_rate
          ) / 30
        )}`
      case 2:
        // yearly
        return `${numberWithCommas(
          Number(
            islandOverview?.monthley_rate
          ) * 12
        )}`
    }
  }

  return (
    <div className="over-body">
      {islandOverview ? (
        <div className="over-f-row">
          <div className="over-f-row-inside">
            <div className="over-f-row-info">
              <div className="over-f-row-item">
                <div className="over-f-row-title">
                  <div>{dictionary['ISLAND-PAGE-OVERVIEW-title1']}</div>
                  <div className="over-f-row-value">
                    {islandOverview?.total_capacity
                      ? islandOverview?.total_capacity === -1
                        ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                        : `$${numberWithCommas(
                          islandOverview?.total_capacity,
                          2
                        )}`
                      : '0'}
                  </div>
                </div>
                {islandOverview?.total_invest_visible === 1 && (
                  <>
                    <div>
                      <div className="over-f-row-title">
                        {dictionary['ISLAND-PAGE-OVERVIEW-title2']}
                      </div>
                      <div className="over-f-row-value">
                        {islandOverview?.available_capacity
                          ? islandOverview?.available_capacity === -1
                            ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                            : `$${numberWithCommas(
                              islandOverview?.available_capacity,
                              2
                            )}`
                          : '0'}
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="over-f-row-title">
                    {islandOverview
                      ? calculateApproximatlyTimeTitle(islandOverview?.approximatily_timeframe)
                      : dictionary['ISLAND-PAGE-OVERVIEW-title21']}
                  </div>

                  <div className="over-f-row-value">
                    {islandOverview ? (
                      <b>
                        {islandOverview?.monthley_rate > 0
                          ? `${calculateApproximatlyTime(islandOverview?.approximatily_timeframe)}%`
                          : '0%'}
                      </b>
                    ) : (
                      <div className="skeleton-element-item skeleton-box"></div>
                    )}
                  </div>
                </div>
              </div>

              <div className="over-f-row-item">
                <div className="over-f-row-title">
                  <div>{dictionary['ISLAND-PAGE-OVERVIEW-title4']}</div>
                  <div className="over-f-row-value">
                    {islandOverview?.referral_plan ? 'Active' : 'Unavailable'}
                  </div>
                </div>
                <div className="over-f-row-title">
                  <div>{dictionary['ISLAND-PAGE-OVERVIEW-title5']}</div>
                  <div className="over-f-row-value">
                    {islandOverview && (
                      <WFPeriod
                        type={islandOverview?.withdraw_frequency_type}
                        data={islandOverview}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className="over-f-row-title">
                    {dictionary['ISLAND-PAGE-OVERVIEW-title6']}
                  </div>
                  <div className="over-f-row-value">
                    {islandOverview?.deadline
                      ? islandOverview?.deadline === -1
                        ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                        : timeToYYYYMMDDHH(Number(islandOverview?.deadline))
                      : ''}
                  </div>
                </div>
              </div>
            </div>
            {/*br */}
            <div className="over-f-row-br"></div>

            <div className="over-f-post">
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title7']}
                </div>
                <div className="over-f-post-inside-value">
                  {islandOverview?.minimum_investment
                    ? `$${numberWithCommas(
                      islandOverview?.minimum_investment,
                      2
                    )}`
                    : '0'}
                </div>
              </div>
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title8']}
                </div>
                <div className="over-f-post-inside-value">
                  {islandOverview?.minimum_topup
                    ? islandOverview?.minimum_topup === -1
                      ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                      : `$${numberWithCommas(islandOverview?.minimum_topup, 2)}`
                    : '0'}
                </div>
              </div>
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title9']}
                </div>
                <div className="over-f-post-inside-value">
                  {islandOverview?.island_duration
                    ? islandOverview?.island_duration === -1
                      ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                      : timeToYYYYMMDD(islandOverview?.island_duration)
                    : ''}
                </div>
              </div>
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title10']}
                </div>
                <div className="over-f-post-inside-value">
                  {islandOverview?.maximum_withdraw
                    ? islandOverview?.maximum_withdraw === -1
                      ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                      : `$${numberWithCommas(
                        islandOverview?.maximum_withdraw,
                        2
                      )}`
                    : '0'}
                </div>
              </div>
              {islandOverview?.non_refundable == 0 && (
                <div className="over-f-post-inside">
                  <div className="over-f-post-inside-title">
                    {dictionary['ISLAND-PAGE-OVERVIEW-title11']}
                  </div>
                  <div className="over-f-post-inside-value">
                    {islandOverview?.lock_period
                      ? islandOverview?.lock_period === -1
                        ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                        : `${Number(islandOverview?.lock_period)} Day${Number(islandOverview?.lock_period) <= 1 ? '' : 's'
                        }`
                      : ''}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="over-f-post-right">
            {islandOverview?.total_invest_visible === 1 ? (
              <>
                <div className="over-f-post-right-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title13']}
                </div>
                <div className="over-f-post-right-value">
                  {islandOverview?.total_participate
                    ? `$${numberWithCommas(
                      islandOverview?.total_participate,
                      2
                    )}`
                    : '0'}
                </div>
                <div className="dashboard">
                  <Flex
                    tabletM={'col'}
                    marginY={6}
                    gap={4}
                    x={'center'}
                    y={'center'}
                    style={{ marginBottom: '7rem' }}
                  >
                    <div className="chart">
                      <div className="progresses">
                        <div className="progress1">
                          <CircleBar
                            percentage={
                              islandOverview &&
                                islandOverview?.total_capacity === -1
                                ? 100
                                : (islandOverview?.total_participate /
                                  islandOverview?.total_capacity) *
                                100
                            }
                          />
                        </div>
                        <div className="progress-content">
                          <div className="over-participate-subject">
                            <h4>
                              {islandOverview &&
                                islandOverview?.total_capacity === -1
                                ? dictionary['ISLAND-PAGE-OVERVIEW-title12']
                                : (islandOverview?.total_participate ||
                                  islandOverview?.total_participate === 0) &&
                                  (islandOverview?.total_capacity ||
                                    islandOverview?.total_capacity === 0)
                                  ? `${numberWithCommas(
                                    (islandOverview?.total_participate /
                                      islandOverview?.total_capacity) *
                                    100,
                                    2
                                  )}%`
                                  : 'Loading...'}
                            </h4>
                            <span>
                              {islandOverview &&
                                islandOverview?.total_capacity === -1
                                ? ''
                                : 'In Use'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Flex>
                </div>
              </>
            ) : (
              <div className="fir-tr-post-l-inside-image">
                <img src={config.server + islandOverview?.image} alt="cover" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="over-f-row">
          <div className="over-f-row-inside">
            <div className="over-f-row-info">
              <div className="over-f-row-item">
                <div className="over-f-row-title">
                  <div>{dictionary['ISLAND-PAGE-OVERVIEW-title1']}</div>
                  <div className="over-f-row-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
                <div>
                  <div className="over-f-row-title">
                    {dictionary['ISLAND-PAGE-OVERVIEW-title2']}
                  </div>
                  <div className="over-f-row-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
                <div>
                  <div className="over-f-row-title">
                    {dictionary['ISLAND-PAGE-OVERVIEW-title21']}
                  </div>
                  <div className="over-f-row-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
              </div>

              <div className="over-f-row-item">
                <div className="over-f-row-title">
                  <div>{dictionary['ISLAND-PAGE-OVERVIEW-title4']}</div>
                  <div className="over-f-row-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
                <div className="over-f-row-title">
                  <div>{dictionary['ISLAND-PAGE-OVERVIEW-title5']}</div>
                  <div className="over-f-row-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
                <div>
                  <div className="over-f-row-title">
                    {dictionary['ISLAND-PAGE-OVERVIEW-title6']}
                  </div>
                  <div className="over-f-row-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
              </div>
            </div>
            {/*br */}
            <div className="over-f-row-br"></div>

            <div className="over-f-post">
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title7']}
                </div>
                <div className="over-f-post-inside-value">
                  <div className="skeleton-element-item island-item skeleton-box"></div>
                </div>
              </div>
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title8']}
                </div>
                <div className="over-f-post-inside-value">
                  <div className="skeleton-element-item island-item skeleton-box"></div>
                </div>
              </div>
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title9']}
                </div>
                <div className="over-f-post-inside-value">
                  <div className="skeleton-element-item island-item skeleton-box"></div>
                </div>
              </div>
              <div className="over-f-post-inside">
                <div className="over-f-post-inside-title">
                  {dictionary['ISLAND-PAGE-OVERVIEW-title10']}
                </div>
                <div className="over-f-post-inside-value">
                  <div className="skeleton-element-item island-item skeleton-box"></div>
                </div>
              </div>
              {islandOverview?.non_refundable == 0 && (
                <div className="over-f-post-inside">
                  <div className="over-f-post-inside-title">
                    {dictionary['ISLAND-PAGE-OVERVIEW-title11']}
                  </div>
                  <div className="over-f-post-inside-value">
                    <div className="skeleton-element-item island-item skeleton-box"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="over-f-post-right">
            {/* TODO */}
            {/* <img
            src="/images/sixtySevenPercent.svg"
            alt="sixtySevenPercent"
          /> */}
            {/* <div className="over-f-post-right-title">
              Total Investments to Date
            </div>
            <div className="over-f-post-right-value"><div className='skeleton-element-item island-investment skeleton-box'></div></div> */}
            <div className="dashboard">
              <Flex
                tabletM={'col'}
                marginY={6}
                gap={4}
                x={'center'}
                y={'center'}
                style={{ marginBottom: '7rem' }}
              >
                <div className="chart">
                  <div className="progresses">
                    <div className="progress1">
                      <CircleBar
                        percentage={
                          islandOverview &&
                            islandOverview?.total_capacity === -1
                            ? 100
                            : (islandOverview?.total_participate /
                              islandOverview?.total_capacity) *
                            100
                        }
                      />
                    </div>
                    <div className="progress-content">
                      <div className="over-participate-subject">
                        <div className="skeleton-element-item island-item skeleton-box"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Flex>
            </div>
          </div>
        </div>
      )}

      {/* till here */}

      {/*br */}
      <div className="over-br"></div>

      {/*user chart and ...*/}
      {islandOverview?.is_visible === 1 && <div className="over-th">
        <div className="over-th-l">
          <div className="over-th-l-inside">
            <div>
              <div className="over-th-l-title">
                {dictionary['ISLAND-PAGE-OVERVIEW-title14']}
              </div>
            </div>

            <select
              className="mymap-s-selector"
              onChange={(e) => setUserChart(e.target.value)}
            >
              <option selected value="monthly">
                {dictionary['ISLAND-PAGE-OVERVIEW-title15']}
              </option>
              <option value="yearly">
                {dictionary['ISLAND-PAGE-OVERVIEW-title16']}
              </option>
            </select>
          </div>
          {userChart === 'monthly' && (
            <>
              {profitChartDailyData ? (
                <UserChartDaily data={profitChartDailyData} />
              ) : (
                <div className="chart-skeleton-container">
                  <div className="skeleton-element-item chart1 skeleton-box"></div>
                </div>
              )}
            </>
          )}
          {userChart === 'yearly' && (
            <>
              {profitChartMonthlyData ? (
                <UserChartMonthly data={profitChartMonthlyData} />
              ) : (
                <div className="chart-skeleton-container">
                  <div className="skeleton-element-item chart1 skeleton-box"></div>
                </div>
              )}
            </>
          )}
        </div>
      </div>}

      {/*chart*/}
      {islandOverview?.total_invest_visible === 1 ? (
        <div className="over-chart">
          <Flex h="unset" x="between">
            <div className="over-chart-title">
              {dictionary['ISLAND-PAGE-OVERVIEW-title17']}
            </div>
            <select
              className="mymap-s-selector"
              onChange={(e) => setTxChart(e.target.value)}
            >
              <option value="monthly">
                {dictionary['ISLAND-PAGE-OVERVIEW-title15']}
              </option>
              <option selected value="yearly">
                {dictionary['ISLAND-PAGE-OVERVIEW-title16']}
              </option>
            </select>
          </Flex>
          {/* <div className="over-chart-content">
          <div className="over-chart-content-s">
            <div className="over-chart-content-s-blue"></div>
            <span>Deposits</span>
          </div>
          <div className="over-chart-content-s">
            <div className="over-chart-content-s-green"></div>
            <span>Withdrawals</span>
          </div>
          <div className="over-chart-content-s">
            <div className="over-chart-content-s-darkblue"></div>
            <span>Profit</span>
          </div>
        </div> */}
          <div className="over-chart-line">
            {txChart === 'monthly' ? (
              <>
                {txChartDailyData ? (
                  <TxChartDaily data={txChartDailyData} />
                ) : (
                  <div className="chart-skeleton-container">
                    <div className="skeleton-element-item chart2 skeleton-box"></div>
                  </div>
                )}
              </>
            ) : null}
            {txChart === 'yearly' ? (
              <>
                {txChartMonthlyData ? (
                  <TxChartMonthly data={txChartMonthlyData} />
                ) : (
                  <div className="chart-skeleton-container">
                    <div className="skeleton-element-item chart2 skeleton-box"></div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      ): null}
    </div>
  );
};

export default Overview;
